import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content container">
        <div className="footer-section about">
          <h2 className="footer-title">About Us</h2>
          <p className="footer-text">We are a team of Passionate engineers and innovators who are experts in Embedded systems, IOT and Data visualization spans multiple engineering disciplines and encompases all phases of product development from ideation to launch.we excel at offering tailored engineering design solution to turning your vision into reality.</p>
        </div>
        <div className="footer-section links">
          <h2 className="footer-title">Quick Links</h2>
          <ul className="footer-links">
            <li><a href="/">Home</a></li>
            <li><a href="/products">Products</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/cart">Cart</a></li>
          </ul>
        </div>
        <div className="footer-section social">
          <h2 className="footer-title">Follow Us</h2>
          <div className="social-icons">
            <a href="https://www.facebook.com" aria-label="Facebook"><FaFacebookF /></a>
            <a href="https://www.twitter.com" aria-label="Twitter"><FaTwitter /></a>
            <a href="https://www.instagram.com" aria-label="Instagram"><FaInstagram /></a>
            <a href="https://www.linkedin.com" aria-label="LinkedIn"><FaLinkedin /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom container">
        <p>&copy; {new Date().getFullYear()} INHLANZII. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
