import React from 'react';
import './HomePage.css'; // Updated CSS

const HomePage = () => {
  return (
    <div className="hero">
      <div className="hero-overlay">
        <div className="hero-content">
          <h1>Empowering a Connected World with Enhanced IoT Solutions</h1>
          <p>Connecting Agriculture, Healthcare, and Industry for a Smarter Future</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
