import React from 'react';
import './RDPage.css';

const RDPage = () => {
  return (
    <div className="rd-page">
      <h1>Research & Development</h1>

      <div className="rd-items">
        <div className="rd-item">
          <img src={require('./assets/images/2.png')} alt="Hunger Prediction" />
          <h2>AI DRIVEN TOOL TO OPTIMIZE FEEDING PROTOCOL IN PATIENTS  ADMITTED IN ICU AND IN PATIENTS WITH  COGNITIVE IMPAIRMENT
          </h2>
          <p>Innovative solution to predict hunger sensations in humans. An IoT-based low-cost EEG device and mobile app is in development. End user application will be an Automatic meal scheduling app useful for Bedridden patients, Muscular Dystrophy, Old Age People.</p>
        </div>
        <div className="rd-item">
          <img src={require('./assets/images/3.png')} alt="Energy Harvesting" />
          <h2>Energy Harvesting</h2>
          <p>Advanced technologies to harness and utilize energy sustainably. Research carried out in Micro Hydro power and Algae.
            Application - 1 - SELF-SUSTAINING SMART WATER FLOW AND LEVEL MONITORING DEVICE USING HYDRO POWER ENERGY HARVESTING
            2 - SELF-SUSTAINING IOT ENHANCED MICRO HYDROPONICS SYSTEM BY PMFC ALGAE</p>
        </div>
        <div className="rd-item">
          <img src={require('./assets/images/1.png')} alt="Hydroponics" />
          <h2>SELF SUSTAINING AUTONOMOUS HYDROPONICS SYSTEM FOR GROWING EXOTIC PLANTS USING PMFC ALGAE AND SOLAR BASED DESICCANT ATMOSPHERIC WATER HARVESTING.
          </h2>
          <p>Design of self-sustaining hydroponic system for cultivating exotic plants using a novel Photosynthetic Microbial Fuel Cell (PMFC) powered by algae and solar energy. This system incorporates solar desalination for water harvesting, creating a closed-loop environment for sustainable indoor plant growth.</p>
        </div>
      </div>
    </div>
  );
};

export default RDPage;
