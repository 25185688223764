import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactPage.css';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;


    // Define dynamic template parameters
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'INHLANZII', // Adjust based on your needs
      message: message,
    };

    // Sending the email
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        alert('Message sent successfully!');
        setName('');  // Reset form inputs after submission
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        alert('Failed to send message. Please try again later.');
      });
  };

  return (
    <div className="contact-page">
      <div className="contact-header">
        <h1>Contact Us</h1>
        <p>Reach out to us today to craft a tailored engineering solution.</p>
      </div>

      <div className="contact-content">
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="user_name"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="user_email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <label>
            Message:
            <textarea
              name="message"
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </label>
          <button type="submit">Send Message</button>
        </form>

        <div className="contact-info">
          <h2>Company Information</h2>
          <p><strong>Address:</strong> 187/238, THIRU.VI KA HIGH ROAD, 3rd FLOOR, 3E, MYLAPORE, CHENNAI-600004</p>
          <p><strong>Phone:</strong> +91 6379062159</p>
          <p><strong>Email:</strong> admin@inhlanzii.com</p>

          <div className="map-container">
            <h3>Our Location</h3>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d222.37000106306212!2d80.26768562884986!3d13.042772471967469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526776e8fd45bb%3A0xd0ae392fedfa61c8!2sSeeragam!5e1!3m2!1sen!2sin!4v1726212092372!5m2!1sen!2sin"
              width="100%" 
              height="300" 
              frameBorder="0" 
              allowFullScreen="" 
              aria-hidden="false" 
              tabIndex="0" 
              title="Company Location"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="appointment">
        <h2>Book an Appointment</h2>
        <p>For Appointment, contact us at <a href="mailto:admin@inhlanzii.com">admin@inhlanzii.com</a>.</p>
      </div>
    </div>
  );
};

export default ContactPage;
