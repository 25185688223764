import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './BillingPage.css';

const BillingPage = () => {
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    address: '',
    paymentMethod: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({
      ...billingDetails,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, redirect to the payment gateway
    navigate('/checkout');
  };

  return (
    <div className="billing-page">
      <h1>Billing Details</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={billingDetails.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Address:
          <input
            type="text"
            name="address"
            value={billingDetails.address}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Payment Method:
          <select
            name="paymentMethod"
            value={billingDetails.paymentMethod}
            onChange={handleChange}
            required
          >
            <option value="creditCard">Credit Card</option>
            <option value="paypal">PayPal</option>
          </select>
        </label>
        <button type="submit">Proceed to Checkout</button>
      </form>
    </div>
  );
};

export default BillingPage;
