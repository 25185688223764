import React from 'react';
import './ServiceListingPage.css';

const ServiceListingPage = () => {
  return (
    <div className="service-page-container">
      <h1>Our Services</h1>
      <div className="service-grid">
        {[
          { title: 'Turnkey EMS', description: 'Complete solutions from design to deployment for effective management systems.' },
          { title: 'PCB Design', description: 'Professional PCB design services for your electronic projects.' },
          { title: 'Enhanced IoT Solutions', description: 'Innovative IoT solutions tailored for agriculture, biomedical, and industrial applications.' },
          { title: 'IoT Product Development', description: 'Custom development for advanced IoT products.' },
          { title: 'Asset Tracking', description: 'Robust solutions for real-time asset tracking and management.' }
        ].map((service, index) => (
          <div key={index} className="service-card">
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceListingPage;
