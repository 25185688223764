import React from 'react';
import { Link } from 'react-router-dom';
import './ProductListingPage.css';

const products = [
  { id: 1, name: 'IoT Gateway Device', image: '/images/iot-gateway.jpg', description: 'IoT gateway device implies different protocols based on the requirement', cost: '$0' },
  { id: 2, name: 'IO-Link', image: '/images/io-link.jpg', description: 'IO-Link Gateway devcie', cost: '$0' },
  { id: 3, name: 'RS485', image: '/images/rs485.jpg', description: 'RS485 communication based gateway device', cost: '$0' },
  { id: 4, name: 'Ethernet', image: '/images/ethernet.jpg', description: 'POE cum Ethernet based gateway device', cost: '$0' },
  { id: 5, name: 'GPS Tracker', image: '/images/gps-tracker.jpg', description: 'GPS tracking device for vechicle diagonostic and tracking', cost: '$0' },
];

const ProductListingPage = () => {
  const handleAddToCart = (product) => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    cartItems.push(product);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  };

  const handleGetQuote = (product) => {
    // Placeholder for the "Get Quote" functionality
    alert(`Requesting a quote for ${product.name}`);
  };

  return (
    <div className="product-listing">
      <h1>Products</h1>
      <div className="product-grid">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} className="product-image" />
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <p className="product-cost">{product.cost}</p>
            <Link to={`/product/${product.id}`} className="view-details-link">View Details</Link>
            <button
              className="add-to-cart-button"
              onClick={() => handleAddToCart(product)}
            >
              Add to Cart
            </button>
            <button
              className="get-quote-button"
              onClick={() => handleGetQuote(product)}
            >
              Get Quote
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductListingPage;
