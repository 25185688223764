import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../pages/assets/images/path2.png'; // Corrected path

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false); // Close menu after clicking a link
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo-container">
          <img src={logo} alt="INHLANZII Logo" className="logo-img" />
          <h1 className="logo">INHLANZII</h1>
        </div>
        <button className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span className="hamburger-icon">{menuOpen ? '✖' : '☰'}</span> {/* Change icon when menu is open */}
        </button>
        <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/products" onClick={closeMenu}>Products</Link></li>
            <li><Link to="/services" onClick={closeMenu}>Services</Link></li>
            <li><Link to="/rd" onClick={closeMenu}>R&D</Link></li>
            <li><Link to="/cart" onClick={closeMenu}>Cart</Link></li>
            <li><Link to="/contact" onClick={closeMenu}>Contact Us</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
