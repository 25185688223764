import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductListingPage from './pages/ProductListingPage';
import ServiceListingPage from './pages/ServiceListingPage';
import CartPage from './pages/CartPage';
import ContactPage from './pages/ContactPage';
import RDPage from './pages/RDPage';
import BillingPage from './pages/BillingPage';
import PaymentPage from './pages/PaymentPage';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductListingPage />} />
        <Route path="/services" element={<ServiceListingPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/rd" element={<RDPage />} />
        <Route path="/billing" element={<BillingPage />} />
        <Route path="/payment" element={<PaymentPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
